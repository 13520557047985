import { styled } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import { useState, useEffect } from "react";

export const CustomSlider = styled(Slider)({
  height: 4,
  "& .MuiSlider-track": {
    color: "#007aff",
    opacity: 0.5,
    height: 2,
  },
  "& .MuiSlider-rail": {
    color: "#cccccc",
    height: 4,
    cap: "",
  },
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#007aff",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
});

export default CustomSlider;
