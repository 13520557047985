import React, { useState, useRef } from "react";
import styles from "./Onboard.module.css";

interface Props {
  onFinish: () => void;
  onBackgroundClick: () => void;
}

const Onboard = ({ onFinish, onBackgroundClick }: Props) => {
  const [page, setPage] = useState(1);
  const overlayRef = useRef<HTMLDivElement>(null);

  const handleNext = () => {
    if (page < 3) {
      setPage(page + 1);
    } else {
      closeOverlay();
    }
  };

  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const closeOverlay = () => {
    onFinish();
    setPage(1);
  };

  const handleBackgroundClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === overlayRef.current) {
      onBackgroundClick();
    }
  };

  const handleBoxSize = () => {
    if (window.innerWidth > 600) {
      return styles.box;
    } else {
      return styles.boxMobile;
    }
  };

  return (
    <div
      className={styles.overlay}
      ref={overlayRef}
      onClick={handleBackgroundClick}
    >
      <div className={handleBoxSize()}>
        <div className={styles.boxContainer}>
          {page === 1 && (
            <div className={styles.page}>
              <div className={styles.textBox}>
                <h2>Test your assumptions</h2>
                <p>
                  This tool aims to uncover implicit assumptions contained in
                  ideas.
                </p>
              </div>
              <div
                className={styles.img}
                style={{
                  background: `url("./logo anticipate.png")`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          )}
          {/* 
          {page === 2 && (
            <div className={styles.page}>
              <div className={styles.textBox}>
                <h2>In life and work, we often encounter unknowns.</h2>
                <p>
                  They can be the deciding factor for an idea to fail or
                  succeed, but they can be very hard to find.
                </p>
              </div>
              <div
                className={styles.img}
                style={{
                  background: `url("./unknown unknowns.svg")`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          )} */}

          {page === 2 && (
            <div className={styles.page}>
              <div className={styles.textBox}>
                <h2>Start by entering an assumption.</h2>
                <p>
                  The assumption can be any idea in combination with an effect
                  that you think it will have.
                </p>
              </div>
              <div
                className={styles.img}
                style={{
                  background: `url("./writing prompts.gif")`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          )}

          {page === 3 && (
            <div className={styles.page}>
              <div className={styles.textBox}>
                <h2>
                  Pressing the blue button will generate implicit assumptions.
                </h2>
                <p>
                  The AI will try to challenge your idea by finding assumptions
                  you might have made during ideation.
                </p>
              </div>
              <div
                className={styles.img}
                style={{
                  background: `url("./adding nodes.gif")`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          )}

          <div className={styles.OnboardButtonContainer}>
            <button
              className={styles.OnboardButtonBack}
              onClick={handlePrev}
              disabled={page === 1}
            >
              Previous
            </button>
            <button className={styles.OnboardButton} onClick={handleNext}>
              {page === 3 ? "Finish" : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboard;
