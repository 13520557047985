import { createContext, Dispatch, SetStateAction } from "react";

type PanelContextType = {
  constraint: string;
  setConstraint: Dispatch<SetStateAction<string>>;
  desirability: number;
  setDesirability: Dispatch<SetStateAction<number>>;
  gptFour: boolean;
  setGptFour: Dispatch<SetStateAction<boolean>>;
  objective: string;
  setObjective: Dispatch<SetStateAction<string>>;
  opportunity: number;
  setOpportunity: Dispatch<SetStateAction<number>>;
  sdg: number;
  setSdg: Dispatch<SetStateAction<number>>;
  temperature: number;
  setTemperature: Dispatch<SetStateAction<number>>;
  unknown: number;
  setUnknown: Dispatch<SetStateAction<number>>;
};

// Create context with default values.
export const PanelContext = createContext<PanelContextType>({
  constraint: "a default Constraint",
  setConstraint: () => undefined,
  desirability: 50,
  setDesirability: () => undefined,
  gptFour: false,
  setGptFour: () => undefined,
  objective: "a default objective",
  setObjective: () => undefined,
  opportunity: 50,
  setOpportunity: () => undefined,
  sdg: 50,
  setSdg: () => undefined,
  temperature: 50,
  setTemperature: () => undefined,
  unknown: 70,
  setUnknown: () => undefined,
});
