import { useCallback } from "react";
import { NodeProps, useReactFlow, getOutgoers } from "reactflow";

import { uuid } from "../utils";

// this hook implements the logic for clicking a workflow node
// on workflow node click: create a new child node of the clicked node
export function useAddClick() {
  const { setEdges, setNodes, getNodes, getEdges, getNode } = useReactFlow();

  const onClick = useCallback(async () => {
    // create a unique id for the child node
    const childNodeId = uuid();

    // create the child node
    const childNode = {
      id: childNodeId,
      // we try to place the child node close to the calculated position from the layout algorithm
      // 150 pixels below the parent node, this spacing can be adjusted in the useLayout hook
      position: { x: 200, y: 200 },
      type: "workflow",
      data: { label: "Enter New Assumption" },
    };

    // create an edge from root to child
    const childEdge = {
      id: `e${"root"}-${childNodeId}`,
      source: "root",
      target: childNodeId,
      animated: true,
    };

    // add the new nodes (child and placeholder), filter out the existing placeholder nodes of the clicked node
    setNodes((nodes) => nodes.concat([childNode]));

    // add the new edge
    setEdges((edges) => [...edges, childEdge]);

    // add the new edges (node -> child, child -> placeholder), filter out any placeholder edges
  }, [getEdges, getNode, getNodes, setNodes, setEdges]);

  return onClick;
}

export default useAddClick;
