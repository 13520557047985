import { NodeTypes } from "reactflow";

import WorkflowNode from "./WorkflowNode";
import HiddenNode from "./HiddenNode";

// two different node types are needed for our example: workflow and placeholder nodes
const nodeTypes: NodeTypes = {
  workflow: WorkflowNode,
  hidden: HiddenNode,
};

export default nodeTypes;
