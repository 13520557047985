import React, { memo, useState, useRef, useEffect } from "react";
import { Handle, Position, NodeProps } from "reactflow";
import cx from "classnames";

import styles from "./NodeTypes.module.css";
import useNodeClickHandler from "../hooks/useNodeClick";

const WorkflowNode = ({ id, data }: NodeProps) => {
  const [label, setLabel] = useState(data.label);
  const nodeRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [clicked, setClicked] = useState(false);

  const onClick = useNodeClickHandler(id);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLabel(e.target.value);
    data.label = e.target.value; // Immediately update the node label as well
  };

  // Update the label state when the data.label changes
  useEffect(() => {
    setLabel(data.label);
  }, [data.label]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${
        textareaRef.current.scrollHeight - 3
      }px`;
      if (nodeRef.current) {
        nodeRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }
  }, [label]);

  return (
    <div ref={nodeRef} className={cx(styles.node)}>
      <div className={styles.inputContainer}>
        <textarea
          ref={textareaRef}
          className={styles.textarea}
          onChange={onChange}
          value={label}
          rows={1}
          style={{ lineHeight: "normal" }}
          title="click to add a child node"
        ></textarea>
        <button className={styles.button} onClick={onClick}>
          <img className={styles.plus} src="../+.svg" />
        </button>
      </div>

      <Handle
        className={styles.handle}
        type="target"
        position={Position.Top}
        isConnectable={false}
      />
      <Handle
        className={styles.handle}
        type="source"
        position={Position.Bottom}
        isConnectable={false}
      />
    </div>
  );
};

export default memo(WorkflowNode);
