import { useState, useCallback } from "react";
import ReactFlow, {
  Background,
  Edge,
  Node,
  ProOptions,
  ReactFlowInstance,
  ReactFlowProvider,
  useReactFlow,
} from "reactflow";

import useLayout from "./hooks/useLayout";
import nodeTypes from "./NodeTypes";
import edgeTypes from "./EdgeTypes";
import styles from "./App.module.css";
import Panel from "./Panel";
import Onboard from "./Onboard";

import "reactflow/dist/style.css";

import { PanelContext } from "./PanelContext";

const proOptions: ProOptions = { account: "paid-pro", hideAttribution: true };

// initial setup: one workflow node and a placeholder node
// placeholder nodes can be turned into a workflow node by click
const defaultNodes: Node[] = [
  {
    id: "root",
    type: "hidden",
    position: { x: 0, y: 0 },
    data: {},
  },
  {
    id: "1",
    data: {
      label: "Building a new street will improve traffic in the city",
      //"Create an eco-friendly park with solar-powered lights, recycled-material benches, rainwater harvesting system.",
    },
    position: { x: 0, y: 0 },
    type: "workflow",
  },
];

// initial setup: connect the workflow node to the placeholder node with a placeholder edge
const defaultEdges: Edge[] = [
  { id: "e1", source: "root", target: "1", type: "hidden" },
];

const fitViewOptions = {
  padding: 0.95,
  includeHiddenNodes: true,
};

function ReactFlowPro() {
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [nodes, setNodes] = useState(defaultNodes);
  const [edges, setEdges] = useState(defaultEdges);
  const { setViewport } = useReactFlow();

  const flowKey = "example-flow";
  const [rfInstance, setRfInstance] = useState<ReactFlowInstance | null>(null);

  const onSave = useCallback(() => {
    console.log(rfInstance);
    console.log("onSave");
    if (rfInstance) {
      console.log("rfInstance");
      const flow = rfInstance.toObject();
      const flowString = JSON.stringify(flow);
      const blob = new Blob([flowString], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "flow.json";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  }, [rfInstance]);

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      const flowString = localStorage.getItem(flowKey);

      if (flowString) {
        const flow = JSON.parse(flowString);
        const { x = 0, y = 0, zoom = 1 } = flow.viewport;
        setNodes(flow.nodes || []);
        setEdges(flow.edges || []);
        setViewport({ x, y, zoom });
      }
    };

    restoreFlow();
  }, [setNodes, setEdges, setViewport]);

  const togglePanelVisibility = () => {
    setIsPanelVisible(!isPanelVisible);
  };

  // this hook call ensures that the layout is re-calculated every time the graph changes
  useLayout();
  try {
    return (
      <>
        <div style={{ position: "absolute", top: 10, right: 10, zIndex: 3 }}>
          <button className={styles.saveButton} onClick={onSave}>
            Save
          </button>
          <button className={styles.saveButton} onClick={onRestore}>
            Restore
          </button>
        </div>
        <div style={{ position: "relative", zIndex: "2" }}>
          <button
            className={styles.toggleButton}
            onClick={togglePanelVisibility}
            style={{
              left: isPanelVisible
                ? window.innerWidth > 1750
                  ? "20%"
                  : "232px"
                : "0%",
              backgroundColor: isPanelVisible ? "#eeeeee" : "#007aff",
              color: isPanelVisible ? "#666" : "#fff",
              marginTop: isPanelVisible ? "24px" : "10px",
            }}
          >
            Panel
          </button>
        </div>

        <div className={styles.app}>
          <div
            className={styles.panelContainer}
            style={{ visibility: isPanelVisible ? "visible" : "hidden" }}
          >
            <Panel />
          </div>

          <div className={styles.canvasContainer}>
            <ReactFlow
              defaultNodes={defaultNodes}
              defaultEdges={defaultEdges}
              proOptions={proOptions}
              fitView
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              fitViewOptions={fitViewOptions}
              minZoom={0.2}
              nodesDraggable={false}
              nodesConnectable={false}
              zoomOnDoubleClick={false}
            >
              <Background color="#dadada" gap={16} size={1.25} />
            </ReactFlow>
          </div>
        </div>
      </>
    );
  } catch (error) {
    console.log(error);
  }
}

function ReactFlowWrapper() {
  const [objective, setObjective] = useState(
    ""
    //"Develop an efficient and cost-effective transportation solution for a large city with a high population of commuters, address the city's limited budget and space constraints, speed is crucial to decrease the increasing dissatisfaction among commuters."
    //"Enhance recreational space, promote community interaction."
  );
  const [constraint, setConstraint] = useState(
    ""
    //"Large city with a high commuting population, limited financial resources and space for transportation solutions, speed is crucial in order to address the growing dissatisfaction"
    //"Limited budget, environmental regulations, existing infrastructures."
  );
  const [temperature, setTemperature] = useState(70);
  const [unknown, setUnknown] = useState(50);
  const [opportunity, setOpportunity] = useState(50);
  const [desirability, setDesirability] = useState(50);
  const [sdg, setSdg] = useState(50);
  const [gptFour, setGptFour] = useState(true);
  const [isOnboardVisible, setIsOnboardVisible] = useState(true);
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [resetKey, setResetKey] = useState(0);

  const handleOnboardFinish = () => {
    setIsOnboardVisible(false);
    setResetKey(resetKey + 1);
  };

  const handleBackgroundClick = () => {
    setIsOnboardVisible(false);
  };

  const handleActivity = () => {
    setLastActivity(Date.now());
  };

  return (
    <div>
      <div onMouseMove={handleActivity} onKeyDown={handleActivity}>
        <ReactFlowProvider>
          <PanelContext.Provider
            value={{
              constraint,
              setConstraint,
              desirability,
              setDesirability,
              gptFour,
              setGptFour,
              objective,
              setObjective,
              opportunity,
              setOpportunity,
              sdg,
              setSdg,
              temperature,
              setTemperature,
              unknown,
              setUnknown,
            }}
          >
            <ReactFlowPro key={resetKey} />
          </PanelContext.Provider>
        </ReactFlowProvider>
      </div>
      {isOnboardVisible && (
        <div style={{ position: "absolute", top: 0, left: 0 }}>
          {isOnboardVisible && (
            <Onboard
              onFinish={handleOnboardFinish}
              onBackgroundClick={handleBackgroundClick}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default ReactFlowWrapper;
